import { useState } from 'react';
import api from '../services/api';
import { useAuth } from '../hook/useAuth';
import { toast } from 'react-toastify';

const useSetAlert = () => {
    const [error, setError] = useState(null);
    const { verifyToken } = useAuth();

    const setAlert = async (addressId, time) => {
        try {
            const token = verifyToken();

            const response = await api.post(
                `/address/alert/${addressId}`,
                { time },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            toast.success('Alerta atualizado com sucesso!');
            return response.data; 
        } catch (error) {
            setError(error);
            toast.error('Ocorreu um problema ao atualizar alerta, por favor, tente novamente!');
        }
    };

    return { error, setAlert };
};

export default useSetAlert;