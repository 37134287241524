
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Map from './Map';
import SelectRoute from './SelectRoute';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  select: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: "500px",
    width: '90%',
    marginTop: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: 20,
    paddingRight: 20,
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogRoute({ routes, route, setRoute }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [kmzUrl, setKmzUrl] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ALL_ROUTES = 'https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=1mInDC0P1q4i8wDfWkGX6oHtsCGGc4ME';

  useEffect(() => {
    if (!route) return;
    if (route != 1 && route != 0) {
      setKmzUrl(route.map ? route.map : null)
    }

    if (route === 1) {
      setKmzUrl(null);
    }

  }, [route])

  const handleChange = event => {
    setRoute(event.target.value);
    setKmzUrl(route.map ? route.map : null)

    if (route === 1) {
      setKmzUrl(null);
    }

    // setOpen(false);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Button variant="outlined" size="small" color="primary" onClick={handleClickOpen}>
            {!route ? 'Escolher Rota' : 'Alterar Rota'}
          </Button>
        </Grid>
        <Grid item xs={5}>
          {route && route != 0 && route != 1 ? (<Typography variant="h6" style={{ fontSize: '14px' }} my={3} > {route.title} </Typography>) : null}
        </Grid>
      </Grid>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Selecione sua Rota
            </Typography>
          </Toolbar>
        </AppBar>
        <Box style={{ height: '100%' }}>

          {route !== 1 && route !== 0 ? <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
            <Button size="large" variant='outlined' color="primary" onClick={handleClose} style={{ position: 'absolute', zIndex: '99', bottom: '20px', background: '#ffffff' }}>
              Confirmar seleção de rota
            </Button>
          </div> : null}

          <SelectRoute route={route} routes={routes} className={classes.select} handleChange={handleChange}></SelectRoute>
          {route === 1 ? <Map kmz={ALL_ROUTES} /> : <Map kmz={kmzUrl} />}
        </Box>
      </Dialog>
    </div>
  );
}