import React, { useState, useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import { url } from '../../services/api.js'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Map from '../../components/Map';
import SelectRoute from '../../components/SelectRoute';
import { useAuth } from '../../hook/useAuth';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  select: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: "500px",
    width: '90%',
    marginTop: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: 20,
    paddingRight: 20,
  },

  days: {
    backgroundColor: '#fff',
    boxShadow: theme.asagee.shadow,
    position: 'absolute',
    marginTop: 67,
    zIndex: 1,
    maxWidth: "460px",
    width: 'calc(100% - 100px)',
    left: '50%',
    paddingLeft: 20,
    paddingRight: 20,
    borderSpacing: 0,
    border: '1px solid #bbb',
    borderRadius: '16.5px',
    transform: 'translateX(-50%)',
    '& li': {
      width: '100%',
      textAlign: 'left',
      padding: '2px 5',
      listStyle: 'none',
      lineHeight: '1.5',
    },
    '& h4': {
      color: theme.palette.primary.main,
      marginBottom: '10px',
      marginTop: '3px',
      textAlign: 'center',
    },
    '& li:last-child': {
      marginBottom: '10px',

    }
  }
}));

export default function Routes() {

  const [kmzUrl, setKmzUrl] = useState('');
  const [routes, setRoutes] = useState([]);
  const { setTitle, app } = useStore();
  const [addresses, setAddresses] = useState([]);

  const [route, setRoute] = useState(0);

  const [daysOfRoute, setDaysOfRoute] = useState([]);
  const [trucks, setTrucks] = useState([]);

  const { checkResponseAuth, verifyToken } = useAuth();

  function handleResponse(response, status = 200) {
    if (response.status !== 200) return false;
    if (!response.data.data) return false;
    checkResponseAuth(response);
    setAddresses(response.data.data[0]);
  }
  function handleError(error) {
    checkResponseAuth(error.response);
  }

  useEffect(() => {
    if (app && app.roads) {
      setRoutes(app.roads);
    }
  }, [app.roads]);

  useEffect(() => {
    if (addresses) {
      const matchingRoute = routes.find(route => route.id === addresses.road?.id);
      if (matchingRoute) {
        setRoute(matchingRoute);
      }
    }
  }, [addresses?.road, routes]);

  useEffect(() => {
    const token = verifyToken();

    return api.get('address', {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(handleResponse)
      .catch(handleError)
  }, []);

  // const urlWithoutApi = url.replace('api/', '');

  const classes = useStyles();

  const weekdaysList = {
    0: 'Segunda',
    1: 'Terça',
    2: 'Quarta',
    3: 'Quinta',
    4: 'Sexta',
    5: 'Sábado',
    6: 'Domingo',
  }


  function routeDays({ weekdays }) {
    const days = [];

    weekdays.forEach((weekday, index) => {
      if (weekday && weekday.length !== 0) {
        days.push(weekdaysList[index]);
      }
    })
    return days;
  }

  const handleChange = (event) => {
    setRoute(event.target.value);
  };

  // const getMapUrl = fileName => {
  //   return `${urlWithoutApi}storage/maps/${fileName}`;
  // }

  // function handleClick(...goto) {
  //   if (goto[0] != null) {
  //     history.push(goto[0])
  //   }
  //   else {
  //     history.goBack()
  //   }

  // }

  useEffect(() => {
    async function initGetApi() {
      const response = await api.get('veiculos');
      setTrucks(response.data.data.vehicles);
    }

    initGetApi();

    let interval = setInterval(async () => {

      let request = await api.get('veiculos');
      setTrucks(request.data.data.vehicles);

    }, 15000);

    return () => {
      clearInterval(interval)
    }

  }, []);

  useEffect(async () => {
    setTitle('Rotas');
  }, []);

  // useEffect(() => {
  //   if (app && app.roads) {
  //     setRoutes(app.roads.filter(item => item.map && item.map.length > 0));
  //   }
  // }, [app.roads]);

  useEffect(() => {
    if (!route) return;
    if (route != 1 && route != 0) {
      setKmzUrl(route.map ? route.map : null)
      setDaysOfRoute(routeDays(route))
    }

    if(route === 1) {
      setKmzUrl(null);
    }
  }, [route])

  return (
    <>
      <SelectRoute className={classes.select} routes={routes} route={route} handleChange={handleChange} />
      {daysOfRoute && daysOfRoute.length !== 0 && route !== 0 && route !== 1 ? <ul className={classes.days}>
        <h4>Dias da rota</h4>
        {daysOfRoute && daysOfRoute.map((item, index) => (<li key={index}>{item}</li>))}
      </ul> : null}

      <Map trucks={trucks} kmz={kmzUrl} route={route} />

    </>
  );
}