import React, { useEffect, useState } from 'react';
import { useStore } from '../../hook/useStore';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useParams, useHistory } from 'react-router-dom';
import './style.css';

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(
    (theme) => ({
        root: {
            boxShadow: theme.asagee.shadow,
            borderRadius: theme.asagee.radius,
            marginBottom: 15,
            minHeight: 56,
            color: theme.palette.primary.main,

            '&$expanded': {
                minHeight: 56,
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
            }
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    })

)(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'block',
    },
}))(MuiAccordionDetails);

export default function Dicas() {
    const { setTitle, app } = useStore();
    const history = useHistory();

    const tips = app.tips;
    const { accordionId } = useParams();

    useEffect(() => {
        if (tips && tips.length > 0) {
            const initialExpandedAccordions = accordionId !== undefined && !isNaN(parseInt(accordionId))
                ? [parseInt(accordionId)]
                : [];
            setExpandedAccordions(initialExpandedAccordions);
        }
    }, [tips, accordionId]);

    const [expandedAccordions, setExpandedAccordions] = useState([]);

    useEffect(() => {
        setTitle('Dicas de separação');
    }, []);

    const handleAccordionClick = (index) => {
        const newExpandedAccordions = [...expandedAccordions];
        const accordionIndex = newExpandedAccordions.indexOf(index);
        if (accordionIndex === -1) {
            newExpandedAccordions.push(index);
        } else {
            newExpandedAccordions.splice(accordionIndex, 1);
        }
        setExpandedAccordions(newExpandedAccordions);
        const newUrl = newExpandedAccordions.length > 0 ? `/dicas/${index}` : '/dicas';
        history.push(newUrl);
    };

    return (
        <Container>
            <Box my={4}>
                {tips && tips.map((item, index) => (
                    <div key={item.id}>
                        <Accordion expanded={expandedAccordions.includes(index)} onChange={() => handleAccordionClick(index)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Box fontWeight={900}>
                                    {item.title}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className='tip-container'>
                                        <div dangerouslySetInnerHTML={{ __html: item.introtext }} />

                                        {item.mobile_image == null && item.image != null ? (
                                            <div className='img-container'>
                                                {item.image != null ? <img src={item.image || ''} alt="Imagem de dica" /> : ''}
                                            </div>
                                        ) : item.image == null && item.mobile_image != null ? (
                                            <div className='img-container mobile_img'>
                                                {item.mobile_image != null ? <img src={item.mobile_image || ''} alt="Imagem de dica" /> : ''}
                                            </div>
                                        ) : (
                                            <>
                                                <div className='img-container desktop_img'>
                                                    {item.image != null ? <img src={item.image || ''} alt="Imagem de dica" /> : ''}
                                                </div>
                                                <div className='img-container mobile_img'>
                                                    {item.mobile_image != null ? <img src={item.mobile_image || ''} alt="Imagem de dica" /> : ''}
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </Box>
        </Container>
    );
}
