import React, { useEffect } from 'react';
import { Button, Container, Link } from '@material-ui/core';
import { useStore } from '../../hook/useStore';
import SecondHeader from '../../components/SecondHeader';
import ButtonIconMain from '../../components/ButtonIconMain';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Message from '@material-ui/icons/Message';
import Outline from '@material-ui/icons/ErrorOutline';
import PersonAdd from '@material-ui/icons/PersonAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import InstallAndroid from '../../components/InstallAndroid';
import { makeStyles } from '@material-ui/core/styles';

import RecyclingIcon from '@mui/icons-material/Recycling';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import { useAuth } from '../../hook/useAuth';

export default function Home(showAndroidInstall) {
  const { getUser } = useAuth();
  const { app } = useStore();

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      color: '#3b5998',
      margin: '8px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '1px solid #3b5998'
    },
  }));

  const useStylesAlign = makeStyles(() => ({
    align: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px'
    },
  }));

  const socials = useStyles();
  const classes = useStylesAlign();

  const { setTitle, setTopShadow } = useStore();
  useEffect(async () => {
    setTitle('');
    setTopShadow(false);
  }, []);


  const configs = app.configs;

  const facebook = configs && configs.filter(item => item.tag === 'facebook')[0];
  const instagram = configs && configs.filter(item => item.tag === 'instagram')[0];

  return (
    <>
      <SecondHeader></SecondHeader>

      <br></br>

      <Container>
        {/* {
          <ButtonIconMain
            text="Onde está o Caminhão"
            Icon={LocalShippingIcon}
            link="/caminhoes"
          />
        } */}
        {
          <ButtonIconMain
            text="Grade de Coletas/Bairros"
            Icon={LibraryBooksIcon}
            link="/coleta"
          />
        }
        <ButtonIconMain text="Onde Está o Caminhão" Icon={LocalShippingIcon} link="/rotas" />

        <ButtonIconMain
          text="Dicas de Separação"
          Icon={RecyclingIcon}
          link="/dicas"
        />
        {!getUser() &&
          <ButtonIconMain text="Cadastrar" Icon={PersonAdd} link="/registrar" />
        }

        {getUser() && (
          <ButtonIconMain text="Alerte-me" Icon={Outline} link="/alerta" />
        )}
        {getUser() && (
          <ButtonIconMain text="Contato" Icon={Message} link="/contato" />
        )}

        {showAndroidInstall && <InstallAndroid />}

        <div className={classes.align}>
          {facebook && facebook.content &&
            <Link href={facebook.content} target='_blank'>
              <Button
                variant="contained"
                className={socials.button}
                startIcon={<FacebookIcon />}
              >
                Facebook
              </Button>
            </Link>
          }

          {instagram && instagram.content &&
            <Link href={instagram.content} target='_blank'>
              <Button
                variant="contained"
                className={socials.button}
                startIcon={<InstagramIcon />}
              >
                Instagram
              </Button>
            </Link>
          }
        </div>

      </Container>
    </>
  );
}

