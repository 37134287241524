import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import SelectAsagee from './SelectAsagee';

const useStyles = makeStyles((theme) => ({
    root: {
        [`& .MuiOutlinedInput-root`]: {
            borderRadius: theme.asagee.radius
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function ({ routes, route, handleChange, className = null }) {
    const classes = useStyles();

    return (
        <FormControl className={[classes.root, className]} variant="outlined" fullWidth>
            <div className={classes.formContainer}>
                <SelectAsagee style={{ minWidth: '100%' }}
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={route}
                    onChange={handleChange}
                >
                    <MenuItem disabled value={0}>
                        <em>Clique e Escolha sua Rota</em>
                    </MenuItem>
                    <MenuItem value={1}>
                        <em>Todas as Rotas</em>
                    </MenuItem>

                    {routes.map(item => (
                        <MenuItem value={item}>{item.title}</MenuItem>
                    ))}

                </SelectAsagee>
            </div>
            {/* <FormHelperText>Some important helper text</FormHelperText> */}
        </FormControl>
    );
}
