import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { toast } from 'react-toastify';
import { useStore } from '../hook/useStore';
import api from '../services/api';
import DialogRoute from '../components/DialogRoute';

function AddressForm({ open, handleClose, addAddress, handleLoading, routes }) {

    const [route, setRoute] = useState(0);
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [time, setTime] = useState(1);
    const [loading, setLoading] = useState(false);
    const { app } = useStore();

    const handleChange = (event) => {
        setTime(event.target.value);
    };

    function handleSubmit(event) {
        event.preventDefault();
        if (! route || route === 0 || route === 1 || street === '' || number === '') {
            toast.error('Preencha todos os campos');
            return;
        }
        handleClose();
        handleLoading(addAddress({ street, number, time, route: route.id }));
        setStreet('');
        setNumber('');
        setTime('');
        setRoute(0);
    }

    function getStreetByGeoLocation(event) {
        setLoading(true);
        event.preventDefault();
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
        function handleError() { 
            toast.error('Geo Localização foi bloqueada pelo seu navegador');
            setLoading(false);
        }
        const handleSuccess = (position) => {
            handleSubmitForm(position.coords);
        }
        if (!navigator.geolocation) {
            toast.error('Geo Localização não é suportada pelo seu navegador');
            setLoading(false);
        }
         navigator.geolocation.getCurrentPosition(handleSuccess, handleError,options);
         
    }

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1500,
            color: '#fff',
        },
    }));

    const showTimes = function() {
        if (app && app.feature) {
            return app.feature.filter((feature) => {
                return feature.name === 'alerts_only_on_start_the_day';
            })
        }
    }

    const classes = useStyles();

    const handleSubmitForm = async (props) => {
        try {
            await api.get(`address/${props.latitude}/${props.longitude}`).then(response => {
                setStreet(response.data.data.street);
                setNumber(response.data.data.number);
                setLoading(false);
            }).catch(error => {
                toast.error('Falha ao preencher automaticamente, preencha os dados');
                setLoading(false);
            });
        } catch (error) {
            toast.error("Erro no Servidor: ".error);
            setLoading(false);
        }
    }

    const Icon = () =>{
        if(navigator.geolocation){
            return <IconButton onClick={getStreetByGeoLocation}  sx={{ p: '10px' }} aria-label="search">
            <LocationOnIcon  />
        </IconButton>
        }else{
            return null;
        }
    }

    return (<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

            <Backdrop className={classes.backdrop}  open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Endereço</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Informe seus dados
                </DialogContentText>

                <TextField
                    onChange={event => setStreet(event.target.value)}
                    value={street}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Rua"
                    type="text"
                    required={true}
                    fullWidth
                    InputProps={{
                        endAdornment: <Icon />
                      }}
                >
                    </TextField>
                

                <TextField
                    onChange={event => setNumber(event.target.value)}
                    value={number}
                    margin="dense"
                    id="name"
                    label="Número"
                    required
                    type="number"
                    fullWidth
                />
                            
                    <Box my={2} alignContent="right">
                        <DialogRoute routes={routes} route={route} setRoute={setRoute}></DialogRoute>
                    </Box>
                
                {   showTimes() && !showTimes()[0].is_enabled ?
                    <Box mt={3}>
                        <RadioGroup required aria-label="gender" name="gender1" value={time} onChange={handleChange}>
                         <FormControlLabel value="1" control={<Radio color="primary" />} label="30 Minutos antes" />
                         <FormControlLabel value="2" control={<Radio color="primary" />} label="1 Hora antes" />
                         <FormControlLabel value="3" control={<Radio color="primary" />} label="2 Horas antes" />
                         </RadioGroup>
                    </Box> : null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button type="submit" color="primary">
                    Adicionar
                </Button>
            </DialogActions>
        </form>
    </Dialog>);
}

export default AddressForm;