import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopNav from './TopNav'
import BottomNav from './BottomNav'
import Menu from './Menu'
import api from '../services/api';

import {useStore} from '../hook/useStore';

import InsallIphone from './InsallIphone';

const useStyles = makeStyles({
    main: {
        overflow: 'hidden',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        height: '100%',
        overflowY: 'scroll',
        position: 'relative'
    },
    bar: {
        // propriedade CSS
        color: props => props.color,
    },
});

export default function Andrey({ children, headerSecond }) {

    const [showIphoneInstall, setShowIphoneInstall] = useState(false);
    const [, setShowAndroidInstall] = useState(false);

    const classes = useStyles();
    let {setApp} = useStore();

    useEffect(checkDevice, []);
    useEffect(getConfig, [setApp]);

    async function getConfig(){
        try {
            const data = await api.get('app');
            if(data.status === 200 && data.data.code === 200){
                setApp(data.data.data);
            }
        } catch(e){
            // GET BY LOCAL STORAGE
        }
      
    }

    function checkDevice() {
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test(userAgent);
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
        // Verifica se deve exibir notificação popup de instalação:
    
        if (!isInStandaloneMode()) {
          if (isIos()) {
            setShowIphoneInstall(true)
          } else {
            setShowAndroidInstall(true);
          }
        }
      }
    

    return (
        <>
         
            <main className={classes.main}>
               
                <TopNav></TopNav>

                <section>
                
                    {headerSecond}
                   
                </section>
                

                <section className={classes.content}>
                

                    {children}

                    
                </section>

                <BottomNav />

                <Menu />
            </main>
            {showIphoneInstall && <InsallIphone />}
        </>
    );
}
