import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2968b7',
        },
    },
    asagee: {
        radius: '15px',
        shadow: '0 5px 10px 0 #00000010, 5px 10px 20px 5px #00000010'
    }
});

export default function Theme({ children }) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}