import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    main: props => ({
        border: 'none',
        background: props.reverse ? theme.palette.primary.main : 'none',
        boxShadow: theme.asagee.shadow,
        color: props.reverse ? '#fff' :theme.palette.primary.main,
        borderRadius: '20px',
        fontSize: '1em',
        height: '70px',
        position: 'relative',
        width: '100%',
        margin: '15px 0',
        cursor: 'pointer'
    }),
    iconBox: props => ({
        boxShadow: theme.asagee.shadow,
        background:  props.reverse ? theme.palette.primary.main :'#ffffff',
        borderRadius: '50%',
        height: '90px',
        width: '90px',
        position: 'absolute',
        top: '-10px',
        left: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2em',
        transition: '.2s',
    }),
    textBox: {
        position: 'absolute',
        height: '50px',
        width: 'calc(100% - 135px)',
        left: '120px',
        top: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        fontWeight: 900,
        textAlign: 'left'
    }
}));

export default function ButtonIconMain({text, Icon, link, reverse = false}){
    const classes = useStyles({reverse});
    const history = useHistory()

    function handleClick(){
        if(!link) return;
        history.push(link);
    }

    return (
        <button onClick={handleClick} className={classes.main}>
            <div className={classes.iconBox}>
                <Icon fontSize="large"/>
            </div>
            <div className={classes.textBox}>
                <div className={classes.text}>
                    {text}
                </div>
            </div>
        </button>
    );
}