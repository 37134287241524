import React from "react";
import Theme from './theme'
import { ToastContainer } from 'react-toastify';
import SwtichRouter from './Router'
import { StoreContextProvider } from './contexts/StoreContext'
import { AuthContextProvider } from './contexts/AuthContext'
import {
	HashRouter
} from "react-router-dom";

export default function App() {
	return (
		<Theme>
			<HashRouter>
				<StoreContextProvider>
					<AuthContextProvider>
						<ToastContainer autoClose={3000} className="toast-container" />
						<SwtichRouter />
					</AuthContextProvider>
				</StoreContextProvider>
			</HashRouter>
			
		</Theme>
	);
}
