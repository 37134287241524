import React, { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  KmlLayer,
} from '@react-google-maps/api';
import { useStore } from '../hook/useStore';
import TruckIcon from '../assets/images/garbage-truck.svg';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = { lat: -29.7588, lng: -50.0476 };
const zoom = 12;

function Map({ trucks, kmz = null, route = null }) {
  const [selectedTruck, setSelectedTruck] = useState(null);
  const { setTitle, app } = useStore();

  const matchedPlates = new Set();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDdppeczzCldaxP2nDveiXr20Jvm_PoOgU',
  });

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{ streetViewControl: false, mapTypeControl: false }}
    >
      {kmz && <KmlLayer url={kmz}></KmlLayer>}

      {app.roads && route?.weekdays && app.roads.map((road) => (
        road.weekdays.map((days) => {
          Object.values(days).forEach(day => {
            route.weekdays.map((routeDays) => {
              Object.values(routeDays).forEach(selectedDay => {
                if (selectedDay.plate == day.plate) {
                  matchedPlates.add(selectedDay.plate)
                }
              });
            })
          });
        })
      ))}

      {
        matchedPlates.size === 0 ? (
          trucks &&
          trucks.map((truck) => (
            <Marker
              key={truck.vehicleId}
              position={{
                lat: parseFloat(truck.latitude),
                lng: parseFloat(truck.longitude),
              }}
              // label={{
              //   text: truck.plate,
              //   className: 'label-truck'
              // }}
              onClick={() => {
                setSelectedTruck(truck);
              }}
              icon={{
                url: TruckIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          ))
        ) : (
          trucks &&
          trucks.map((truck) =>
            matchedPlates.has(truck.plate) ? (
              <Marker
                key={truck.vehicleId}
                position={{
                  lat: parseFloat(truck.latitude),
                  lng: parseFloat(truck.longitude),
                }}
                // label={{
                //   text: truck.plate,
                //   className: 'label-truck'
                // }}
                onClick={() => {
                  setSelectedTruck(truck);
                }}
                icon={{
                  url: TruckIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            ) : null
          )
        )
      }
  
      {selectedTruck && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedTruck.latitude),
            lng: parseFloat(selectedTruck.longitude),
          }}
          onCloseClick={() => {
            setSelectedTruck(null);
          }}
        >
          <div>
            <h4> Placa: {selectedTruck.plate}</h4>
            <p> Ignição: {selectedTruck.ignition}</p>
            <p> Velocidade: {selectedTruck.speed} KM/H</p>
            <p> LAT: {selectedTruck.latitude}</p>
            <p> LONG: {selectedTruck.longitude}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
