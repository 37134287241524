import React, { useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CardActions,
    Switch,
    Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900
    },
    delete: {
        marginLeft: 'auto',
    },
    toggleAlignment: {
        display: 'flex',
        gap: '40px',
        flexWrap: 'wrap',
        paddingLeft: '8px'
    },
    textMargin: {
        margin: 0
    },
    alignCard: {
        display: 'flex',
        alignItems: 'end'
    }
}));

export default function Address({ address, handleDeleteAddress, handleChangeAlert, handleChangeAlertSms, handleChangeAlertEmail }) {

    const [checked, setChecked] = React.useState(address.receive_alert ? true : false);
    const [checkedEmail, setCheckedEmail] = React.useState(address.receive_alert_email ? true : false);
    const [checkedSms, setCheckedSms] = React.useState(address.receive_alert_sms ? true : false);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('female');
    const radioGroupRef = React.useRef(null);

    useEffect(() => {
        setChecked(address.receive_alert ? true : false);
        setCheckedEmail(address.receive_alert_email ? true : false);
        setCheckedSms(address.receive_alert_sms ? true : false);
    }, [address]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const handleEntering = () => {
        console.log('radioGroupRef', radioGroupRef)
        if (radioGroupRef.current != null) {
            console.log('radioGroupRef.current.focus()', radioGroupRef.current.focus())
            radioGroupRef.current.focus();
        }
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const toggleChecked = (event) => {
        handleChangeAlert(address.id, !checked);
    };

    const toggleCheckedEmail = (event) => {
        handleChangeAlertEmail(address.id, !checkedEmail);
    };

    const toggleCheckedSms = (event) => {
        handleChangeAlertSms(address.id, !checkedSms);
    };

    const timeToText = time => {
        switch (time) {
            case 1:
                return '30 Minutos Antes'
                break;
            case 2:
                return '1 Hora antes'
                break;
            case 3:
                return '2 Horas antes'
                break;

            default:
                break;
        }
    }

    const classes = useStyles();

    return (
        <Box my={3}>

            <Card mt={4} >
                <Link to={`/definir-alerta/${address.id}`} style={{ textDecoration: 'none' }}>
                    <CardContent>

                        <Typography color="textSecondary" gutterBottom>
                            Endereço
                        </Typography>

                        <Typography variant="h5" color="textPrimary" component="h2">
                            {address.street}, {address.number}
                        </Typography>

                    </CardContent>
                </Link>

                <CardActions disableSpacing className={classes.alignCard}>
                    <Typography>
                        <div className={classes.toggleAlignment}>
                            <div>
                                <p className={classes.textMargin}>Alerta Email:</p>
                                <Grid component="label" container alignItems="end" spacing={1}>
                                    <div>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Switch checked={checkedEmail} onChange={toggleCheckedEmail} color='primary' />}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>

                            <div>
                                <p className={classes.textMargin}>Alerta SMS:</p>
                                <Grid component="label" container alignItems="end" spacing={1}>
                                    <div>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Switch checked={checkedSms} onChange={toggleCheckedSms} color='primary' />}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </Typography>
                    <IconButton onClick={() => handleDeleteAddress(address.id)} className={classes.delete}>
                        <DeleteForeverIcon />
                    </IconButton>

                </CardActions>

            </Card>

        </Box>
    )
}
