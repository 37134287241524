import React, { useEffect, useState } from 'react';
import { useStore } from '../../hook/useStore';
import { Box, Container, Typography } from '@material-ui/core/';

import TextFieldAsagee from '../../components/TextFieldAsagee';
import MaskedInput from 'react-text-mask';
import validator from 'validator';

import { useAuth } from '../../hook/useAuth';
import { useAddress } from '../../hook/useAddress';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Address from '../../components/Address';
import ButtonAsagee from '../../components/ButtonAsagee';
import ModalExclude from '../../components/DialogModal';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import { toast } from 'react-toastify';

export default function Home() {
  const { getUser, verifyToken, logout } = useAuth();
  const [phoneSanitize, setPhoneSanitize] = useState('');
  const [phone, setPhone] = useState(getUser().phone);

  const [name] = useState(getUser().name);
  const [email] = useState(getUser().email);
  const [id] = useState(getUser().id);

  const history = useHistory();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);

  const [loading, setLoading] = useState(false);

  const { getAddresses, addresses, deleteAddress, changeAlert} = useAddress();

  const useStyles = makeStyles((theme) => ({
    title: {
      color: '#999',
      fontWeight: 900,
      fontSize: '16px',
      marginBottom: 15,
    },
    subTitle: {
      color: theme.palette.primary.main,
      fontWeight: 900,
      fontSize: '16px',
      marginBottom: 15,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000000000000000000000000000000,
      color: '#fff',
    },
  }));

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          '(',
          /[1-9]/,
          /\d/,
          ')',
          ' ',
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  const handleSubmitForm = async () => {
    if (!isValidPassword()) return false;
    setLoading(true);
    try {
      const data = await api.patch('register', {
        id,
        name,
        email,
        password,
        password_confirmation: password2,
        phone: phoneSanitize,
      });
      if (data.data.code === 200) {
        toast.success('Dados alterados com sucesso');
        setPassword('');
        setPassword2('');
      }
    } catch (error) {
      toast.error(error.response);
    }
    handleLoadingClose();
  };

  const isValidPhone = () => {
    if (validator.isEmpty(phoneSanitize)) return false;
    if (!validator.isLength(phoneSanitize, { min: 10, max: 11 })) return false;
    return true;
  };

  const isValidPassword = () => {
    if (validator.isEmpty(password) && validator.isEmpty(password2))
      return true;
    if (password !== password2) return false;
    if (!validator.isLength(password, { min: 6, max: 20 })) return false;
    return true;
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };

  useEffect(() => {
    if(phone) setPhoneSanitize(phone.replace(/[^0-9.]/g, ''));
  }, [phone]);

  const { setTitle, setTopShadow } = useStore();
  useEffect(async () => {
    setTitle('Perfil');
    setTopShadow(false);
  }, []);

  function handleLoading(promise) {
    setLoading(true);
    promise.finally(() => setLoading(false));
  }
  function handleDeleteAddress(id) {
    handleLoading(deleteAddress(id));
  }

  function handleChangeAlert(id, checked) {
    handleLoading(changeAlert(id, checked));
}

  async function excludeUser() {
    setLoading(true);
    try {
      const data = await api.delete('user', {
        headers: {
          Authorization: `Bearer ${verifyToken()}`,
        },
      });
      if (data.data.code === 200) {
        handleLoadingClose();
        toast.success('Conta excluida com sucesso!');
        logout();
        history.push('/');
      }
    } catch (error) {
      toast.error(error.response);
      handleLoadingClose();
    }
  }

  async function sendData() {
    setLoading(true);
    try {
      const data = await api.post(
        'lgpd',
        {},
        {
          headers: {
            Authorization: `Bearer ${verifyToken()}`,
          },
        }
      );
      if (data.data.code === 200) {
        handleLoadingClose();
        toast.success('Email enviado com sucesso!');
      } else {
        handleLoadingClose();
        toast.success('Falha ao enviar o email!');
      }
    } catch (error) {
      toast.error(
        'Falha ao enviar o email, entre em contato com o administrador do sistema'
      );
      handleLoadingClose();
    }
  }

  const classes = useStyles();

  useEffect(() => {
    handleLoading(getAddresses());
  }, []);

  return (
    <>
      <Container>
        <Backdrop
          style={{ zIndex: 9999 }}
          open={loading}
          onClick={handleLoadingClose}
          className={classes.Backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box my={4}>
          <Typography variant="h6" className={classes.subTitle}>
            Dados Pessoais
          </Typography>
          <TextFieldAsagee
            required
            value={name}
            fullWidth
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            disabled
          />
          <TextFieldAsagee
            required
            value={email}
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            disabled
          />
          <TextFieldAsagee
            InputProps={{ inputComponent: TextMaskCustom }}
            error={!isValidPhone()}
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            fullWidth
            id="outlined-basic"
            label="Whatsapp"
            variant="outlined"
            disabled
          />

          <Box my={4}>
            <form autoComplete="off">
              <Typography variant="h6" className={classes.subTitle}>
                Senha
              </Typography>
              <TextFieldAsagee
                error={!isValidPassword()}
                list="autocompleteOff"
                autoComplete="nope"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                type="password"
                id="outlined-basic"
                label="Senha"
                variant="outlined"
              />
              <TextFieldAsagee
                error={!isValidPassword()}
                list="autocompleteOff"
                autoComplete="nope"
                required
                value={password2}
                onChange={(event) => setPassword2(event.target.value)}
                fullWidth
                type="password"
                id="outlined-basic"
                label="Repita a senha"
                variant="outlined"
              />
            </form>
          </Box>
          <Box my={4}>
            <ButtonAsagee
              onClick={handleSubmitForm}
              fullWidth
              variant="contained"
              color="primary"
            >
              EDITAR
            </ButtonAsagee>
          </Box>
          <Divider />

          <Box mt={4}>
            <Typography variant="h6" className={classes.subTitle}>
              Endereços cadastrados
            </Typography>
            {addresses &&
              addresses.length > 0 &&
              addresses.map((address) => (
                <Address
                  handleDeleteAddress={handleDeleteAddress}
                  handleChangeAlert={handleChangeAlert}
                  address={address}
                />
              ))}
          </Box>
        </Box>
        <Divider />
        <Box my={4}>
          <ButtonAsagee
            fullWidth
            onClick={() => setOpenModalEmail(true)}
            variant="contained"
            color="primary"
          >
            Enviar informações por e-mail
          </ButtonAsagee>
          <ButtonAsagee
            fullWidth
            onClick={() => setOpenModal(true)}
            variant="contained"
            color="primary"
          >
            Excluir conta
          </ButtonAsagee>

          <ModalExclude
            needOpen={openModal}
            title={'Excluir conta'}
            confirmText={'Excluir'}
            description={
              'ATENÇÃO - Você está prestes a excluir toda e qualquer informação pessoal sua que consta em nosso banco de dados. Para continuar utilizando os serviços depois desta exclusão, você terá que informar seus dados novamente e efetuar um novo cadastro. Deseja prosseguir assim mesmo?'
            }
            onClose={() => setOpenModal(false)}
            onConfirm={() => excludeUser()}
          />
          <ModalExclude
            needOpen={openModalEmail}
            title={'Enviar dados por e-mail'}
            confirmText={'Enviar'}
            description={
              'Deseja receber todos os dados que temos sobre sua conta por e-mail?'
            }
            onClose={() => setOpenModalEmail(false)}
            onConfirm={() => sendData()}
          />
        </Box>
      </Container>
    </>
  );
}
