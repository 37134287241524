import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import Container from '@material-ui/core/Container';
import { Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useParams } from 'react-router-dom';
import useSetAlert from '../../hook/useSetAlert';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900
    },
    button: {
        color: '#fff',
        backgroundColor: '#3b5998',
    },
}));

function Hours() {

    const { addressId } = useParams();
    const { error, setAlert } = useSetAlert();

    const [value, setValue] = React.useState('1');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        setAlert(addressId, value);
    };

    const { setTitle, app } = useStore();
    const classes = useStyles();

    useEffect(() => {
        setTitle('Definir');
    }, []);

    return (
        <Container>
            <Box mt={4}>
                <Typography variant="h6" className={classes.title}>Defina o horário do alerta:</Typography>

                <FormControl component="fieldset">
                    <RadioGroup aria-label="time" name="time" value={value} onChange={handleChange}>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="30 Minutos antes" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="1 Hora antes" />
                        <FormControlLabel value="3" control={<Radio color="primary" />} label="2 Horas antes" />
                    </RadioGroup>

                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleSubmit} 
                    >
                        Definir
                    </Button>
                </FormControl>

            </Box>
        </Container>
    );
}

export default Hours;