import { Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(
  (theme) => ({
    root: {
      boxShadow: theme.asagee.shadow,
      borderRadius: theme.asagee.radius,
      backgroundColor: '#fff',
      width: '100%',
      color: theme.palette.primary.main,
      outline: 'none',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: theme.asagee.radius,
        },
      },

    },


  })

)(Select);