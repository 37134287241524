import axios from 'axios';

const url = process.env.NODE_ENV === 'production' ? 'https://reciclarcapaodacanoa.com.br/api/' : 'http://127.0.0.1:8000/api/';

const api = axios.create({
    'baseURL': url,
});

export {url};
export default api;
